import { createTheme } from "@mui/material"
import {responsiveFontSizes} from "@mui/material/styles"

const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        // Purple and green play nicely together.
        main: "#0099BD", //#17BEBB
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#FFFFFF", //#F6C453
      },
      background: {
        default: "#000000",
      },
    },
    typography: {
      fontFamily: ["Lato", "Nunito Sans"].join(","),
    },
  });

  const lightTheme = responsiveFontSizes(createTheme({
    palette: {
      mode: "light",
      primary: {
        // Purple and green play nicely together.
        main: "#1e2125", //#17BEBB,
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#FFFFFF", //#F6C453
      },
      background: {
        default: "rgb(233, 223, 206)",
      },
      text: {
        primary: "#1e2125"
        }
    },
    typography: {
      fontFamily: ["Lato", "Nunito Sans"].join(","),
    //   h1:{
    //         fontSize: '14px'
    //    }
    },
  }));


export {
    darkTheme,
    lightTheme
};