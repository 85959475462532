import './App.css';
import AppRouter from "./routes/routes";


function App() {
  return (
          <AppRouter />
  );
}

export default App;
