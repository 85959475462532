import { Box, Typography } from "@mui/material"
import { motion } from "framer-motion"


const HeaderSection = () => {

    return(
        <div>
            <Box px={10}>
                <Box display='flex' sx={{alignItems:'center',height:'100vh'}}>
                    <Box sx={{textAlign:'left' }}> 
                        <motion.div
                        variants={{
                            hidden: {opacity:0, y: 75},
                            visible: {opacity:1, y:0}
                        }}
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 0.5, delay:0.25}}
                        
                        ><Typography variant='h1' >
                            Hey! <br/>I'm Tenzin Sim
                        </Typography>
                        <Box pb={20}>
                            I'm a Computer Science student
                        </Box>
                        
                        </motion.div>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default HeaderSection
