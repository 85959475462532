import { useRef, useState } from "react"
import React from 'react';
import HeaderSection from "../Components/header"
import Wave from "../Components/wave"
// import Sun from "../Components/sun"
// import TimelineSection from "../Components/timeline"
import About from "../Components/about"
import { Box, Grid, Typography } from "@mui/material"
// import { Parallax, ParallaxLayer } from '@react-spring/parallax'
// import HelloImg from "../assets/images/HelloNoBG.png"
// import HelloImg from "../assets/images/hello.jpg"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence } from 'framer-motion';


function Section({ content }) {
    const { ref, inView } = useInView({
      triggerOnce: false, // Each section only animates once
      threshold: 0.8, // Trigger when 10% of the section is visible
    });
  
    return (
      <Box
        ref={ref}
        sx={{
        //   minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <AnimatePresence>
          {inView && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
              style={{
                pointerEvents: 'none',
                // maxWidth: '800px',
                textAlign: 'center',
              }}
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    );
  }

const HomePage = () => {
    const ref = useRef()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [height, setHeight] = useState(0);
   
    return(
        <div>
            <Box sx={{position:'fixed',zIndex:-1}}>
            <HeaderSection/>
            </Box>
            <Box sx={{height:'80vh',padding:0,margin:0}}>
            {[10,20,30,40,50].map((i)=>(<Wave index={i}/>))}
            </Box>
            <Box sx={{ width: '100vw', position: 'relative'}}>
              <Box
                sx={{
                  height: '100vh',
                  backgroundColor: 'rgb(233, 223, 206)',
                  display: 'block',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin:0
                }}
              >

                  {/* Scrollable content sections */}
                <Box sx={{ width: '100vw', position: 'relative' }}>
                    <Section
                    title="About Me"
                    content={<About/>}
                    />
                    {/* <Section
                    title="My Work"
                    content="I've had the opportunity to work on various projects, including [describe projects or achievements]. My work focuses on [describe focus or goals]."
                    /> */}
                    {/* <Section
                    title="Hobbies and Interests"
                    content="In my free time, I enjoy [hobbies or activities], and I'm always looking to learn and grow. Whether it's [specific hobby] or [another interest], I find joy in exploring new things."
                    /> */}
                    {/* <Section
                    title="Contact Me"
                    content="Feel free to reach out via [contact information or methods]. I'm always open to discussing new projects, collaborations, or just having a chat!"
                    /> */}
                </Box>
        </Box>
      </Box>
      
            {/* <Parallax ref={ref} pages={4}>
                <ParallaxLayer offset={0} sticky={{start:0, end:0.3}} style={{zIndex:-1}}>
                    <HeaderSection/>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={0} factor={1} >
                    {[10,20,30,40,50].map((i)=>(<Wave index={i}/>))}
                   
                </ParallaxLayer>
                <ParallaxLayer offset={0.99} speed={0}><Box sx={{backgroundColor:'background.default', width:'100%', height:'300%'}}></Box></ParallaxLayer>
                {isMobile ? 
                <>
                <ParallaxLayer offset={0.99} speed={0}>
                    <Box>
                    <About/>
                    <Box sx={{px:5, py:5}}><Box component="img" sx={{width:'100%'}} src={HelloImg}/></Box>
                    </Box>
                </ParallaxLayer>
                </>
                :(
                <>
                <ParallaxLayer sticky={{start:0.99, end:1.4}}>
                    <Grid container>
                        <Grid item sm={6}><About setHeight={setHeight}/></Grid>  
                        <Grid item sm={6} />
                    </Grid>                  
                </ParallaxLayer>
                <ParallaxLayer offset={1.4}>
                    <Grid container>
                        <Grid item sm={6}>
                            <Box mt={`${height}px`}/>
                            <TimelineSection/>
                        </Grid>  
                        <Grid item sm={6} />
                    </Grid>                  
                </ParallaxLayer>
                <ParallaxLayer offset={1.5} speed={0.5} style={{zIndex:1}}>
                <Grid container>
                    <Grid item sm={6} />
                    <Grid item sm={6}>
                        <Box display="flex" sx={{justifyContent:'center', p:5}}>
                            <Box component="img" sx={{width:'525px', maxWidth:"100%"}} boxShadow={'100px'} src={HelloImg}/>
                        </Box>
                    </Grid>
                </Grid>
                </ParallaxLayer>
                <ParallaxLayer offset={1.9} speed={2} >
                <Grid container>
                    <Grid item sm={6} />
                    <Grid item sm={6}>
                        <Box display="flex" sx={{justifyContent:'center', p:5}}>
                            <Box sx={{width:0,borderLeft: '380px solid transparent',
                            borderRight: '380px solid transparent', 
                            borderBottom: '550px solid BurlyWood',
                            rotate:'180deg'
                            }}/>
                        </Box>
                    </Grid>
                </Grid>
                </ParallaxLayer>
                </> 
                )}
                
            </Parallax> */}
        </div>
    )
}



export default HomePage
