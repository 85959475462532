import { Box, Typography } from "@mui/material"
import { motion } from "framer-motion"
import { useRef, useEffect, useState } from "react"



const About = (props) => {
    const { setHeight } = props;
    const ref = useRef();
  
    // useEffect(() => {
    //     if (ref.current) {
    //     if (typeof setHeight === 'function') {setHeight(ref.current.clientHeight)}
    //     function handleResize() {
    //         // Update the state or perform any other actions when the
    //         // browser is resized
    //         if (typeof setHeight === 'function') {
    //         setHeight(ref.current.clientHeight)
    //         }
    //       }
          
          
    //       // Attach the event listener to the window object
    //       window.addEventListener('resize', handleResize);
      
    //       // Remove the event listener when the component unmounts
    //       return () => {
    //         window.removeEventListener('resize', handleResize);
    //       };
    //     }

    // },[ref.current])
    return(
        <div>
            <Box pl={{xs:5,sm:10}} pr={{xs:5,sm:10}} pt={{xs:'100px',lg:'150px'}} maxWidth={'800px'}>
                <Box display='flex' sx={{alignItems:'top', justifyContent:'left'}}>
                    <Box sx={{textAlign:'center' }}> 
                        <Typography variant='h2' >
                            About Me
                        </Typography>
                        <Typography variant='p' >
                        Highly driven Singapore Polytechnic computing graduate specializing in Applied AI and Analytics.
                        Passionate about applications of AI, large language models, machine learning and business
                        analytics, I am seeking opportunities to hone my skills in software engineering and data analytics
                        to help companies develop solutions and make better decisions.
                        </Typography>

                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default About
