import { useEffect, useState, useRef } from "react"


const Wave = (props) => {
    const { index } = props;
    const canvasRef = useRef(null)
    const canvasFillRef = useRef(null)
    const [counter, setCounter] = useState(0.0)
    const [frameRate, setFrameRate] = useState(30); // Set your desired frame rate (frames per second)

    useEffect(() => {
      const canvas = canvasRef.current;
      const canvas2 = canvasFillRef.current;
      const ctx = canvas.getContext("2d");
      const ctx2 = canvas2.getContext("2d");
      ctx2.strokeStyle = 'transparent';
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = 'black';
    
      const f = [3 + Math.sin(index / 1.5), 2, 6];
      const counterRad = counter * Math.PI / 180;
      const a = [1, 2 * Math.cos(counterRad), 2 * Math.sin(counterRad)];
      const b = [1, 1 - Math.cos(index / 1.5) * 2, Math.sin(index / 1.5) * 2];
    
      let lastDrawTime = Date.now();
      let animationFrameId;
    
      const animate = () => {
        const now = Date.now();
        const elapsed = now - lastDrawTime;
    
        if (elapsed > (1000 / frameRate)) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx2.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx2.beginPath();

          for (let x_val = 0; x_val <= 2.3; x_val += 0.1) {
            var y = 0;
            for (let i = 0; i < f.length; i++) {
              y += a[i] * Math.cos(f[i] * x_val + counterRad) + b[i] * Math.sin(f[i] * x_val + counterRad);
            }
            var x = x_val / 2 * canvas.width;
            y = (canvas.height - canvas.height / 5) + y * 30;
    
            if (x_val === 0) {
              ctx.moveTo(0, y);
              ctx2.moveTo(0, y);
              var prev_x = 0
              var prev_y = y
              var prev_prev_x = 0
              var prev_prev_y = y
            } else {
              ctx.bezierCurveTo((prev_prev_x+prev_x)/2,(prev_prev_y+prev_y)/2,prev_x,prev_y,(prev_x+x)/2,(prev_y+y)/2);
              ctx2.bezierCurveTo((prev_prev_x+prev_x)/2,(prev_prev_y+prev_y)/2,prev_x,prev_y,(prev_x+x)/2,(prev_y+y)/2);
              prev_prev_x = prev_x
              prev_prev_y = prev_y
              prev_x = x
              prev_y = y

            }
          }

          // Close the path at the bottom of the canvas
            ctx2.lineTo(x, canvas.height);
            ctx2.lineTo(0, canvas.height);
            ctx2.closePath();

            // Fill the curve
            ctx2.fillStyle = 'rgba(233, 223, 206,0.6)'; 
            ctx2.fill();
    
          ctx.stroke();
          ctx2.stroke();
          lastDrawTime = now;
          setCounter(prevCounter => (prevCounter + 1) % 360);
        }
    
        animationFrameId = requestAnimationFrame(animate);
      };
    
      animationFrameId = requestAnimationFrame(animate);
    
      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }, [counter, index, canvasRef, frameRate]);

    return(
        <div>
            <canvas ref={canvasRef} width={1000} height={2000} style={{position:'absolute',top:0,width:'100%',height:'80vh',zIndex:1,bottom:0}}/>
            <canvas ref={canvasFillRef} width={1000} height={2000} style={{position:'absolute',top:0,width:'100%',height:'80vh'}}/>
        </div>
    )
}

export default Wave
