import {
    BrowserRouter,
    Routes, 
    Route,
  } from "react-router-dom";

import HomePage from "../Pages/home";


function AppRouter(props) {
    return(
        <main>
        <div classname="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="*" element={<div>error</div>}/>
                </Routes>
            </BrowserRouter>
        </div>
        </main>
    )
}

export default AppRouter